import { Router } from 'preact-router'
import Home from './routes/home'
import './styles/index.scss'

const App = () => (
  <div id='app'>
    <Router>
      <Home path='/' />
    </Router>
  </div>
)

export default App
